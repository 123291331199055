<template>
    <teleport to=".css--main">
        <div class="fixed inset-0 z-40 bg-natt bg-opacity-80 flex">
            <section class="p-5 text-svart m-auto">
                <div class="relative block rounded-xl p-6 space-y-4 mb-10" :class="bg">
                    <ConfettiExplosion
                        v-if="showConfetti"
                        :particle-count="300"
                        :particle-size="10"
                        :force="1"
                        :duration="5000"
                        :colors="['#005aa4', '#247a5d', '#e44244', '#f8b181']"
                        class="left-1/2 top-40"
                    />
                    <h2 class="mt-10 mx-auto px-6 text-center text-8 relative block">
                        <slot name="heading"></slot>
                    </h2>
                    <div v-if="anim">
                        <Lottie :src="anim" />
                    </div>
                    <div v-if="icon" class="relative text-16 text-center" style="z-index: 1201">
                        <img :src="icon" class="block mx-auto" />
                    </div>
                    <slot name="content"></slot>
                </div>
                <button
                    v-if="showBack"
                    class="btn !py-4 !px-5 bg-white flex items-center gap-2 mx-auto"
                    type="button"
                    @click="$emit('unready')"
                >
                    <svg-icon name="chevron" class="text-vann w-5 h-5 rotate-90" />
                    <span>{{ t('Jeg var ikke ferdig likevel') }}</span>
                </button>
            </section>
        </div>
    </teleport>
</template>

<script setup lang="ts">
import ConfettiExplosion from 'vue-confetti-explosion';
defineProps({
    showConfetti: {
        type: Boolean,
        default: false
    },
    icon: {
        type: String,
        default: ''
    },
    anim: {
        type: String,
        default: ''
    },
    bg: {
        type: String,
        required: true
    },
    showBack: {
        type: Boolean,
        default: false
    }
});
const { t } = useI18n();
</script>
