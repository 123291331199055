<template>
    <div v-if="show" class="fixed inset-0" @click="show = false"></div>
    <div
        class="fixed z-10 -mt-16 bottom-0 inset-x-0 px-4 pt-2 pb-32 bg-natt text-white transition-all"
        :class="show ? 'top-32 overflow-y-auto' : 'top-full'"
    >
        <ContentRenderer :value="data || []">
            <button @click="show = !show" class="relative text-left font-small w-full">
                <div class="flex items-center">
                    <span class="relative p-2 bg-white rounded-full mr-2">
                        <svg-icon name="ordbok" class="w-8 h-8" />
                        <span
                            class="absolute top-1/2 left-1/2 -ml-1 -mt-3 text-natt font-bold -rotate-12"
                        >
                            {{ priorityWords.length }}
                        </span>
                    </span>
                    <div>
                        <span class="block font-bold">{{ data?.heading }}</span>
                        <span>{{ priorityWords.map(w => w.title).join(', ') }}</span>
                    </div>
                    <svg-icon
                        name="chevron"
                        class="absolute right-0 top-1/2 -mt-1.5 w-3 h-3"
                        :class="{ 'rotate-180': !show }"
                    />
                </div>
            </button>
            <div>
                <template v-if="priorityWords.length">
                    <h2 class="mt-6 mb-3">{{ data?.usingnow }}:</h2>
                    <ul class="text-natt space-y-3">
                        <li v-for="word in priorityWords" class="shadow rounded-xl p-4 bg-white">
                            <button
                                class="w-full flex justify-between items-center"
                                type="button"
                                @click="word.open = !word.open"
                            >
                                <span>{{ word.title }}</span>
                                <span
                                    class="rounded-full border border-natt h-9 w-9 flex items-center"
                                    :class="word.open ? 'rotate-180' : ''"
                                >
                                    <svg-icon name="chevron" class="w-5 h-5 mx-auto" />
                                </span>
                            </button>
                            <div v-if="word.open" class="text-svart">
                                {{ word.content }}
                            </div>
                        </li>
                    </ul>
                </template>
                <h2 class="mt-6 mb-3">{{ data?.all }}:</h2>
                <ul class="text-natt space-y-3">
                    <li v-for="word in words" class="shadow rounded-xl p-4 bg-white">
                        <button
                            class="w-full flex justify-between items-center"
                            type="button"
                            @click="word.open = !word.open"
                        >
                            <span>{{ word.title }}</span>
                            <span
                                class="rounded-full border border-natt h-9 w-9 flex items-center"
                                :class="word.open ? 'rotate-180' : ''"
                            >
                                <svg-icon name="chevron" class="w-5 h-5 mx-auto" />
                            </span>
                        </button>
                        <Collapse :when="word.open">
                            <div class="text-svart">
                                {{ word.content }}
                            </div>
                        </Collapse>
                    </li>
                </ul>
                <div class="fixed inset-x-0 bottom-8">
                    <button
                        v-show="show"
                        class="rounded-full shadow-hard bg-white text-fjell flex items-center px-8 py-3 mx-auto"
                        @click="show = false"
                    >
                        <svg-icon name="pluss" class="w-5 h-5 rotate-45" /> {{ data?.close }}
                    </button>
                </div>
            </div>
        </ContentRenderer>
    </div>
</template>

<script setup lang="ts">
import { Collapse } from 'vue-collapsed';
const props = defineProps({
    page: {
        type: Number,
        required: true
    }
});
const show = ref(false);
const words = ref<Dictionary[]>();
const copy = ref<Dictionary[]>();
const localPage = toRef(props, 'page');
const { locale } = useI18n();
const { data } = await useAsyncData('dict-data', () =>
    queryContent(`${locale.value}/dictionary`).findOne()
);
onMounted(() => {
    queryContent(`${locale.value}/dictionary`)
        .findOne()
        .then(res => {
            words.value =
                res.words.map((b: Dictionary) => {
                    return { ...b, open: false };
                }) ?? [];
            words.value?.sort((a, b) => {
                return a.title < b.title ? -1 : 1;
            });

            copy.value = JSON.parse(JSON.stringify(words.value));
        });
});
const priorityWords = computed((): Dictionary[] => {
    return copy.value?.filter(w => w.pages.includes(props.page)) || [];
});

watch([localPage], () => {
    show.value = false;
});
</script>
