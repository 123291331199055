<template>
    <main v-if="data">
        <ContentRenderer :value="data">
            <div class="hidden sm:block fixed inset-0 bg-frost overflow-hidden">
                <div class="absolute top-1/2 -mt-10 right-10 w-44 h-44 rounded-full bg-sol"></div>
                <div
                    class="absolute left-[-50vw] w-[200vw] -bottom-96 top-1/2 rounded-[50%] bg-vann"
                ></div>
                <svg-icon name="sky-liten" class="css--sky absolute top-4 -left-96 w-60 h-12" />
                <svg-icon name="sky-stor" class="css--sky absolute top-44 -left-96 w-60 h-16" />
                <svg-icon name="sky-liten" class="css--sky absolute top-80 -left-96 w-60 h-16" />
                <svg-icon name="sky-stor" class="css--sky absolute top-24 -left-96 w-60 h-24" />
                <svg-icon name="sky-liten" class="css--sky absolute top-44 -left-96 w-64 h-32" />
            </div>
            <div
                class="hidden sm:block w-full sm:max-w-md mx-auto sm:h-[90vh] sm:max-h-[750px] sm:fixed sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2"
            >
                <div
                    class="bg-natt bg-opacity-30 w-full h-1/3 absolute bottom-0 left-0 skew-x-[26deg] rounded-xl origin-bottom"
                ></div>
                <div
                    class="bg-natt bg-opacity-30 w-full h-1/3 absolute bottom-0 left-0 -skew-x-[26deg] rounded-xl origin-bottom"
                ></div>
            </div>
            <div
                class="css--main w-full sm:max-w-md mx-auto relative bg-vann sm:h-[90vh] sm:max-h-[750px] sm:border-8 sm:border-svart sm:rounded-2xl overflow-hidden sm:fixed sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2"
            >
                <div v-if="crackShitUp" class="fixed inset-0 pointer-events-none z-50">
                    <img src="~/assets/img/knustskjerm.png" class="w-full object-fill" />
                </div>
                <div
                    class="fixed inset-0 z-40 bg-natt text-center flex flex-col justify-center pb-20 transition-transform @container"
                    :class="locked && currentIndex > 0 ? 'translate-x-0' : 'translate-x-full'"
                >
                    <span class="font-heading1">{{ data?.watchscreen }}</span>
                    <svg-icon name="ikke-mobil" class="w-32 h-32 mx-auto mt-10" />
                </div>
                <feedback
                    v-if="locked && currentIndex === 8"
                    anim="/lottie/shrug.json"
                    bg="bg-bær-30"
                >
                    <template #heading>Ups!</template>
                    <template #content>
                        <div class="mt-10 text-center px-6">
                            <span class="block font-heading3 mb-2">
                                {{ data?.brokenphone.title }}
                            </span>
                            {{ data?.brokenphone.text }}
                        </div>
                    </template>
                </feedback>
                <template v-if="currentPage">
                    <div
                        class="fixed overflow-hidden inset-0 z-20 bg-svart duration-500 transition-all ease-linear pointer-events-none @container"
                        :class="[showLoader ? 'bg-opacity-50' : 'bg-opacity-0']"
                    ></div>
                    <div
                        class="fixed inset-0 z-30 @container"
                        :class="showLoader ? 'translate-0-full' : 'translate-y-[120%]'"
                    >
                        <section
                            v-if="currentIndex === 0"
                            class="bg-frost-30 -mx-4 text-vann h-full relative"
                        >
                            <div class="relative pt-10">
                                <!-- <svg-icon name="loader" class="w-[80vw] h-[80vw] m-auto css--loader" /> -->
                                <div class="text-center absolute top-8 inset-x-10 text-vann z-10">
                                    <div class="mx-auto mb-4">
                                        <Lottie
                                            src="/lottie/venter.json"
                                            :width="160"
                                            :height="160"
                                        />
                                    </div>
                                    <h1 class="">{{ data?.waiting }}</h1>
                                    <div
                                        class="bg-white rounded shadow inline-block py-2 px-3 text-natt mt-20"
                                    >
                                        <strong>{{ counter }}</strong> av
                                        <strong>{{ connected }}</strong> {{ data?.ready }}
                                    </div>
                                </div>
                            </div>
                            <div class="absolute -bottom-10 css--back flex">
                                <svg-icon name="wave1" class="w-96 h-52 fill-fjell" />
                                <svg-icon name="wave1" class="w-96 h-52 fill-fjell -ml-px" />
                                <svg-icon name="wave1" class="w-96 h-52 fill-fjell -ml-px" />
                            </div>
                            <div class="absolute -bottom-20 css--front flex">
                                <svg-icon name="wave1" class="w-96 h-52 fill-vann" />
                                <svg-icon name="wave1" class="w-96 h-52 fill-vann -ml-px" />
                                <svg-icon name="wave1" class="w-96 h-52 fill-vann -ml-px" />
                            </div>
                        </section>
                        <section v-else class="p-5 text-svart @container">
                            <div class="relative block bg-frost-30 rounded-xl p-6 space-y-4 mb-4">
                                <h2 class="mt-2 mx-auto text-center text-8 relative block">
                                    {{ data?.supert }}
                                </h2>
                                <div class="text-16 text-center">
                                    <Lottie
                                        src="/lottie/highfive.json"
                                        :loop="true"
                                        :width="150"
                                        :height="150"
                                    />
                                </div>
                                <p class="text-center" v-html="data?.wait"></p>
                                <template v-if="loaderInfo">
                                    <div
                                        v-html="loaderInfo"
                                        class="bg-white rounded-lg p-3 border-2 border-sand"
                                    ></div>
                                </template>
                            </div>
                            <button
                                class="btn !py-4 !px-5 bg-white flex items-center gap-2 mx-auto"
                                type="button"
                                @click="playerNotReady"
                            >
                                <svg-icon name="chevron" class="text-vann w-5 h-5 rotate-90" />
                                <span>{{ data?.undo }}</span>
                            </button>
                        </section>
                    </div>

                    <component
                        :is="currentPage"
                        ref="page"
                        :socket="socket"
                        :locked="locked"
                        @msg="sendMsg"
                        @done="playerReady"
                        @toggle-account="toggleAccount()"
                        @crack-shit-up="crackShitUp = $event"
                        @unready="playerNotReady"
                        class="h-full sm:overflow-y-auto @container"
                    />
                    <Dictionary v-if="currentIndex > 0" :page="currentIndex" />
                    <ClientOnly v-if="$config.public.env != 'production'">
                        <div
                            :class="[
                                isActive ? 'bg-skog-70' : 'bg-fjell',
                                'text-center absolute let-0 top-0 z-10  p-1'
                            ]"
                        >
                            {{ isActive ? 'wake aktiv' : 'wake ikke aktiv' }}
                        </div>
                    </ClientOnly>
                </template>
            </div>
        </ContentRenderer>
    </main>
</template>

<script setup lang="ts">
import { useClientStore } from '~/stores/ClientStore';
import { useWakeLock } from '@vueuse/core';
const route = useRoute();
const { locale } = useI18n();
const { data } = await useAsyncData('main-data', () =>
    queryContent(`${locale.value}/client/main`).findOne()
);
definePageMeta({
    layout: 'client'
});

useSeoMeta({
    title: `Rom ${route.params.code}`
});

const clientStore = useClientStore();
const userStore = useUserStore();

const ctx = useNuxtApp();
const showLoader = ref(false);
const loaderInfo = ref('');
const counter = ref(0);
const currentPage = shallowRef('');
const currentIndex = ref(-1);
const connected = ref(0);
const locked = ref(false);
const page = ref();
const crackShitUp = ref(false);
const { request, isActive } = useWakeLock();

let socket: any;
onMounted(() => {
    clientStore.init(route.params.code.toString());
    socket = ctx.$nuxtSocket({ path: '/ws/socket.io' });
    socket.emit('join-room', route.params.code);
    socket.on('clients', (msg: string) => {
        connected.value = parseInt(msg);
    });

    socket.on('navigate', ({ num, type }: { num: number; type: string }) => {
        if (type === 'client') {
            loadPage(num);
        }
    });
    socket.on('load-room', loadPage);
    socket.on('player-ready', async (msg: string) => {
        counter.value = parseInt(msg);
        if (!isActive.value) {
            try {
                await request('screen');
            } catch (e) {
                // console.log('vanlig', e);
            }
        }
    });

    socket.on('connect', () => {
        socket.emit('join-room', route.params.code);
    });

    socket.on('lock', () => {
        locked.value = true;
        if (currentIndex.value === 8) {
            crackShitUp.value = true;
        }
        if (currentIndex.value === 16) {
            locked.value = false;
        }
    });

    socket.on('unlock', () => {
        locked.value = false;
    });

    socket.on('gief-stats-plz', () => {
        try {
            page.value?.sendStats();
        } catch (ex) {
            console.error(ex);
        }
    });
    window.addEventListener('focus', () => {
        socket.emit('join-room', route.params.code);
    });
});

const loadPage = (num: number) => {
    if (isNaN(num) || num === null) {
        navigateTo('/rom');
        return;
    } else if (num === currentIndex.value) {
        locked.value = false;
        return;
    }
    currentIndex.value = num;
    showPage();
};

const showPage = () => {
    nextTick(async () => {
        loaderInfo.value = '';
        crackShitUp.value = currentIndex.value === 8 || currentIndex.value === 9;

        if (currentIndex.value > 1 && clientStore.getInitialSpendingAmount === 0) {
            clientStore.fillDefaultInitialSpending();
        }
        if (currentIndex.value > 2 && clientStore.getBaseIncome === 0) {
            clientStore.fillDefaultIncomes();
        }
        if (currentIndex.value > 4 && clientStore.saveAmount === 0) {
            clientStore.fillDefaultSaving();
        }
        if (currentIndex.value > 6 && clientStore.adjustedSpendings.length === 0) {
            clientStore.initialSpendings.forEach(val => {
                clientStore.adjustedSpendings.push({
                    ...val,
                    amount: val.amount * 0.9
                });
            });
            clientStore.saveAmount = 800;
        }
        if (currentIndex.value > 8 && !clientStore.phone.value) {
            clientStore.setRandomPhone();
        }
        if (currentIndex.value > 9 && !clientStore.payment.value) {
            clientStore.setRandomPayment();
        }

        import(`../../../components/client-pages/page-${currentIndex.value}.vue`).then(
            async res => {
                showLoader.value = false;
                window.scrollTo({
                    top: 0
                });
                currentPage.value = res.default;
                if (currentIndex.value === 16) {
                    locked.value = false;
                }
            }
        );
    });
};

const sendMsg = (msg: any) => {
    socket.emit('chat message', {
        id: userStore.id,
        ...msg
    });
};

const playerReady = (opts: { html: string; disableLoader: boolean }) => {
    socket.emit('player-ready', { id: userStore.id });
    showLoader.value = !opts.disableLoader;
    if (opts.html) {
        loaderInfo.value = opts.html;
    }
};

const playerNotReady = () => {
    socket.emit('player-not-ready', { id: userStore.id });
    showLoader.value = false;
};

const showAccount = ref(false);
const toggleAccount = () => {
    showAccount.value = true;
};

clientStore.$subscribe((mutation, state) => {
    localStorage.setItem(`state-${route.params.code}`, JSON.stringify(state));
});
</script>

<style scoped>
.css--loader {
    animation: spinner 2s infinite linear;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.css--back svg {
    animation: wave 8.1s infinite linear;
}

.css--front svg {
    animation: wave 5.3s infinite linear;
}

@keyframes wave {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.css--sky {
    animation: sky infinite linear;
}
.css--sky:nth-child(3) {
    animation-duration: 160s;
    animation-delay: -96s;
}
.css--sky:nth-child(4) {
    animation-duration: 145s;
    animation-delay: -85s;
}
.css--sky:nth-child(5) {
    animation-duration: 133s;
    animation-delay: 44s;
}
.css--sky:nth-child(6) {
    animation-duration: 121s;
    animation-delay: -20s;
}
.css--sky:nth-child(7) {
    animation-duration: 102s;
}

@keyframes sky {
    0% {
        left: -20%;
    }
    100% {
        left: 110%;
    }
}
</style>
